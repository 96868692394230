<!-- eslint-disable -->
<template>
  <v-app id="home">
    <!-- <home-bar /> -->
    <home-header />
    <div style="padding:50px 300px;height:700px">
      <div class="tab">
        <button class="tablinks active" @click="openTab($event, 'info')">Informations</button>
        <button class="tablinks" @click="openTab($event, 'agency')">Agence TLM</button>
      </div>
      <div id="info" class="tabcontent" style="display:block">
        <h5>Votre colis {{tracker}}</h5>
        <div style="height:40px;width:40px;border-radius:50%;background-color:#ccc;display:inline-block;margin:10px 60px;text-align:center;justify-content:center" v-for="status in statuses" :key="status.status">
          <div v-if="status.status != 'Livrée'">
            <v-icon v-if="status.exist == true" style="color:green;height:40px;width:40px;">mdi-check</v-icon>
            <v-icon v-else style="color:red;height:40px;width:40px;">mdi-check</v-icon>
            <span>{{status.status}}</span>
          </div>
          <div v-else>
            <v-icon v-if="status.exist == true" style="color:green;height:40px;width:40px;">mdi-home</v-icon>
            <v-icon v-else style="color:red;height:40px;width:40px;">mdi-home</v-icon>
            <span>{{status.status}}</span>
          </div>
        </div>
        <br><br>
        <div style="background-color: #ccc;padding:10px;width:100%">
          <div><strong>Numéro de référence interne</strong><span style="float:right">{{trackerInfo.colis.tracking_id}}</span></div>
          <div><strong>N° de colis</strong><span style="float:right">{{trackerInfo.colis.tracking_id}}</span></div>
          <div><strong>Poids du colis</strong><span style="float:right">{{trackerInfo.colis.weight}} KG</span></div>
          <div><strong>Crée le </strong><span style="float:right">{{trackerInfo.colis.creation_date}}</span></div>
          <div><strong>Livré le</strong><span style="float:right">{{trackerInfo.colis.delivery_date}}</span></div>
        </div>
        <br>
        <h5>Les étapes de ma livraison</h5>
        <table>
          <tr>
            <th>Date</th>
            <th>Heure</th>
            <th>Etape</th>
            <th>Localisation</th>
          </tr>
          <tr v-for="status in this.trackerInfo.colis.history" :key="status.message">
            <td>{{moment(status.creation_date).format('DD/MM/YYYY')}}</td>
            <td>{{moment(status.creation_date).format('HH:mm')}}</td>
            <td>{{status.message}}</td>
            <td>{{status.communue + ', ' + status.wilaya}}</td>
          </tr>
        </table>
      </div>
      <div id="agency" class="tabcontent">
        <div style="height:40px;width:40px;border-radius:50%;background-color:#ccc;display:inline-block;margin:10px 60px;text-align:center;justify-content:center" v-for="status in statuses" :key="status.status">
          <div v-if="status.status != 'Livrée'">
            <v-icon v-if="status.exist == true" style="color:green;height:40px;width:40px;">mdi-check</v-icon>
            <v-icon v-else style="color:red;height:40px;width:40px;">mdi-check</v-icon>
            <span>{{status.status}}</span>
          </div>
          <div v-else>
            <v-icon v-if="status.exist == true" style="color:green;height:40px;width:40px;">mdi-home</v-icon>
            <v-icon v-else style="color:red;height:40px;width:40px;">mdi-home</v-icon>
            <span>{{status.status}}</span>
          </div>
        </div>
        <div style="background-color: #ccc;padding:10px;width:100%">
          <div style="width:50%;display:inline-block">
            <h5>{{trackerInfo.depot.name}}</h5>
            <h6>{{trackerInfo.depot.address}}</h6>
            <h6>{{trackerInfo.depot.communue + ', ' + trackerInfo.depot.wilaya}}</h6>
            <hr style="width:100%">
            <p>Agence ouverte: du samedi au jeudi</p>
            <p>Horaire d'ouvertue: 09:00-12:00 13:00-16:00</p>
            <hr style="width:100%">
            <p>Nous écrire: {{trackerInfo.depot.email}}</p>
            <p>Nous appeler: {{trackerInfo.depot.phone}}</p>
            <strong>
              Consignes de sécurité<br>
              Afin de garantir votre sécurité et celle de notre personnel, merci de respecter le sens de circulation, la vitesse limitée ainsi que les places de stationnement réservées dans votre agence TLM.
            </strong>
          </div>
          <div style="width:50%;display:inline-block">
            <iframe :src="'https://www.google.com/maps/embed/v1/search?key=AIzaSyCyWPq-620FBb5nvt0nweoOEVza_pl6N9k&q='+trackerInfo.depot.address + '+'+trackerInfo.depot.communue+'+'+trackerInfo.depot.wilaya" style="width:100%;" height="300px" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
    <home-footer/>
  </v-app>
</template>

<script>
/* eslint-disable */
  import HomeBar from './components/core/HomeBar.vue'
  import moment from 'moment'
  import HomeHeader from './HomeHeader.vue';
  import axios from 'axios'
  export default {
    name: 'AboutUs',
    components: {
      HomeHeader,
      HomeBar,
      HomeFooter: () => import('./components/core/HomeFooter'),
    },
    data() {
      return {
        moment: moment,
        statuses : [
          {'exist':false,'status':'Created'},
          {'exist':false,'status':'Confirmé'},
          {'exist':false,'status':'Chargement'},
          {'exist':false,'status':'EnRoute'},
          {'exist':false,'status':'Arrivé'},
          {'exist':false,'status':'Livrée'}
        ],
        selectedWilaya: {
          name:null,
          communues: []
        },
        selectedCommunue: null,
        trackingMessage: {
          show: false,
          status: null,
          message: null
        },
        loginMessage: {
          show: false,
          status: null,
          message: null
        },
        tracking_id: null,
        showPackageModal: false,
        messageWhenNoItems: 'There are not items',
        timelineItems: [
          // {
          //   from: new Date(2018, 7,1),
          //   title: 'Name',
          //   description:
          //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
          // },
        ],
        messagereseved: null,
        cemail: null,
        cname: null,
        telephone: null,
        text: null,
        emailexist: null,
        semail: null,
        spassword: null,
        name: null,
        address: null,
        phone: null,
        wilaya: null,
        communue: null,
        email: null,
        auth_token: null,
        password: null,
        hover1: false,
        hover2: false,
        hover3: false,
        stand1: false,
        stand2: false,
        stand3: false,
        colClass: '',
        colClass2: '',
        postCenters: null,
        selectedPost: 'ANB',
        tracker:null,
        trackerInfo: null
      }
    },
    mounted() {
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.tracker = this.$route.fullPath.split('?tracking_id=')[1]
      if (this.tracker != null && this.tracker != undefined) {
        this.getTrackingStatus()
      } else {
        alert('You Have An Error In Your Link')
      }
    },
    methods: {
      openTab(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className += " active";
      },
      getTrackingStatus() {
        axios.post('get-tracking-status', {
          tracking_id: this.tracker
        }).then(response => {
          if (response.data.status == 'success') {
            this.trackerInfo = response.data.data
            for (let i in this.trackerInfo.colis.history) {
              for (let j in this.statuses) {
                if (this.trackerInfo.colis.history[i].message == this.statuses[j].status) {
                  this.statuses[j].exist = true
                }
              }
            }
          } else {
            alert('This Tracking ID Does Not Exist')
          }
        }).catch(error => {
          alert('This Tracking ID Does Not Exist')
        })
      },
    }
  }
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
.v-application--wrap {
    min-height: 100vh;
    justify-content: center;
}
.container {
  text-align: center;
}
.main-content {
}
#home {
  background-color: white;
}
input {
    border: 2px;
    border-color: black;
    background: white;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  height: 500px;
  overflow-y:auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

</style>